import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-boost";
import { authProvider } from "../Auth/authProvider";
import configData from "../config.json";

const cache = new InMemoryCache();

const envCheck = () => {
  return "https://" + configData.API.Server + configData.API.Path + "graphql";
};

export const client = new ApolloClient({
  uri: envCheck(),
  cache,
  request: async (operation) => {
    const token = await authProvider.getIdToken();
    const idToken = token.idToken.rawIdToken;
    const accessToken = await authProvider.getAccessToken();
    const access = accessToken.accessToken;
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${idToken}` : "",
        Access: access,
      },
    });
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (networkError && networkError.statusCode === 401) {
    }
  },
});
