import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { Layout } from "./Shared/Layout";
import Ringloader from "./components/Progress/Ringloader";
import SubNav from "./components/MyRequests/SubNav";
import { SubLayout } from "./components/MyRequests/SubLayout";
import Loadable from "react-loadable";

export const Routes = {
  DelegatesWithData: Loadable({
    loader: () => import("./components/Delegates"),
    loading() {
      return <Ringloader />;
    },
  }),
  //NOTE: Azure Migration - preloader reference
  MyDelegateWithData: Loadable({
    loader: () => import("./components/Delegates/MyDelegate"),
    loading() {
      return <Ringloader />;
    },
  }),
  ApprovalChains: Loadable({
    loader: () => import("./components/ApprovalChains"),
    loading() {
      return <Ringloader />;
    },
  }),
  Permissions: Loadable({
    loader: () => import("./components/Permissions"),
    loading() {
      return <Ringloader />;
    },
  }),
  AddUser: Loadable({
    loader: () => import("./components/Permissions/Users/AddUser"),
    loading() {
      return <Ringloader />;
    },
  }),
  AddGroup: Loadable({
    loader: () => import("./components/Permissions/Groups/AddGroup"),
    loading() {
      return <Ringloader />;
    },
  }),
  UpdateUser: Loadable({
    loader: () => import("./components/Permissions/Users/UpdateUser"),
    loading() {
      return <Ringloader />;
    },
  }),
  UpdateGroup: Loadable({
    loader: () => import("./components/Permissions/Groups/UpdateGroup"),
    loading() {
      return <Ringloader />;
    },
  }),

  Help: Loadable({
    loader: () => import("./components/Help/Help"),
    loading() {
      return <Ringloader />;
    },
  }),

  TimelineModal: Loadable({
    loader: () => import("./components/AllRequests/Modals/TimelineModal"),
    loading() {
      return <Ringloader />;
    },
  }),

  ManageRequests: Loadable({
    loader: () => import("./components/ManageRequests"),
    loading() {
      return <Ringloader />;
    },
  }),

  EditApproverModalWithData: Loadable({
    loader: () =>
      import("./components/ApprovalChains/Modals/EditApproverModal"),
    render(loaded, props) {
      let Comp = loaded.EditApproverModalWithData;
      return <Comp {...props} />;
    },
    loading() {
      return <Ringloader />;
    },
  }),

  ApproveWithData: Loadable({
    loader: () => import("./components/Email/Approve"),
    render(loaded, props) {
      let Comp = loaded.ApproveWithData;
      return <Comp {...props} />;
    },
    loading() {
      return <Ringloader />;
    },
  }),

  DenyWithData: Loadable({
    loader: () => import("./components/Email/Deny"),
    render(loaded, props) {
      let Comp = loaded.DenyWithData;
      return <Comp {...props} />;
    },
    loading() {
      return <Ringloader />;
    },
  }),

  PendingRequestsWithData: Loadable({
    loader: () => import("./components/MyRequests/PendingRequests"),
    render(loaded, props) {
      let Comp = loaded.PendingRequestsWithData;
      return <Comp {...props} />;
    },
    loading() {
      return <Ringloader />;
    },
  }),

  RequestHistoryWithData: Loadable({
    loader: () => import("./components/MyRequests/RequestHistory"),
    render(loaded, props) {
      let Comp = loaded.RequestHistoryWithData;
      return <Comp {...props} />;
    },
    loading() {
      return <Ringloader />;
    },
  }),

  RequestTimelineWithData: Loadable({
    loader: () => import("./components/MyRequests/Modal/RequestTimelineModal"),
    render(loaded, props) {
      let Comp = loaded.RequestTimelineWithData;
      return <Comp {...props} />;
    },
    loading() {
      return <Ringloader />;
    },
  }),

  AllRequestsWithData: Loadable({
    loader: () => import("./components/AllRequests/AllRequests"),
    render(loaded, props) {
      let Comp = loaded.AllRequestsWithData;
      return <Comp {...props} />;
    },
    loading() {
      return <Ringloader />;
    },
  }),

  LocalITApproversWithData: Loadable({
    loader: () => import("./components/LocalITApprovers/LocalITApprovers"),
    render(loaded, props) {
      let Comp = loaded.LocalITApproversWithData;
      return <Comp {...props} />;
    },
    loading() {
      return <Ringloader />;
    },
  }),
};

const {
  LocalITApproversWithData,
  AllRequestsWithData,
  RequestTimelineWithData,
  RequestHistoryWithData,
  PendingRequestsWithData,
  DenyWithData,
  ApproveWithData,
  AddGroup,
  AddUser,
  UpdateUser,
  DelegatesWithData,
  MyDelegateWithData,
  UpdateGroup,
  Permissions,
  ManageRequests,
  EditApproverModalWithData,
  TimelineModal,
  Help,
  ApprovalChains,
  graphExplorer,
} = Routes;

//FIXME: Azure Migration DONE 08/08/2023 - replace pidKey with userAzureObjectId
export const renderAdmin = (role, userAzureObjectId, name, userNt) => {
  return (
    <Layout name={name} userRole="Admin" ntId={userNt}>
      <Switch>
        <Route
          path="/MyDelegate"
          render={(props) => (
            <MyDelegateWithData
              {...props}
              userAzureObjectId={userAzureObjectId}
            />
          )}
        />
        <Route exact path="/ManageRequests" component={ManageRequests} />
        <Route exact path="/Permissions" component={Permissions} />
        <Route exact path="/Permissions/User/New" component={AddUser} />
        <Route exact path="/Permissions/Group/New" component={AddGroup} />
        <Route exact path="/Permissions/User/Edit" component={UpdateUser} />
        <Route exact path="/Permissions/Group/Edit" component={UpdateGroup} />
        <Route exact path="/AllRequests" component={AllRequestsWithData} />
        <Route exact path="/ApprovalChains" component={ApprovalChains} />
        <Route
          exact
          path="/LocalITApprovers"
          component={LocalITApproversWithData}
        />
        <Route
          exact
          path="/Help"
          render={(props) => <Help {...props} role={role} />}
        />
        <Route exact path="/Approve/:id" component={ApproveWithData} />
        <Route exact path="/Deny/:id" component={DenyWithData} />
        <Route exact path="/Timeline/:id" component={TimelineModal} />
        <Route
          exact
          path="/ApprovalChain/Edit/:id/:ops"
          component={EditApproverModalWithData}
        />
        <Route
          exact
          path="/MyRequests/Pending/:id"
          component={RequestTimelineWithData}
        />
        <Route exact path="/ApprovalDelegates" component={DelegatesWithData} />
        <Route
          path="/MyDelegate"
          render={(props) => (
            <MyDelegateWithData
              {...props}
              userAzureObjectId={userAzureObjectId}
            />
          )}
        />
        <SubLayout>
          <Route exact path="/MyRequests" component={SubNav} />
          <Switch>
            <Route
              exact
              path="/MyRequests/Pending"
              component={PendingRequestsWithData}
            />
            <Route
              exact
              path="/MyRequests/History"
              component={RequestHistoryWithData}
            />
            <Redirect exact from="/MyRequests" to="/MyRequests/Pending" />
            <Redirect from="/" to="/ManageRequests" />
          </Switch>
        </SubLayout>
      </Switch>
      {/* This will redirect to home when entering non existing route */}
    </Layout>
  );
};

//FIXME: Azure Migration DONE 08/08/2023 - replace pidKey with userAzureObjectId
export const renderPowerUser = (role, userAzureObjectId, name, userNt) => {
  return (
    <Layout name={name} userRole="Power User" ntId={userNt}>
      <Switch>
        <Route exact path="/ManageRequests" component={ManageRequests} />
        <Route
          path="/MyDelegate"
          render={(props) => (
            <MyDelegateWithData
              {...props}
              userAzureObjectId={userAzureObjectId}
            />
          )}
        />
        <Route path="/Approve/:id" component={ApproveWithData} />
        <Route path="/Deny/:id" component={DenyWithData} />
        <Route
          path="/MyRequests/Pending/:id"
          component={RequestTimelineWithData}
        />
        <Route
          exact
          path="/Help"
          render={(props) => <Help {...props} role={role} />}
        />
        <SubLayout>
          <Route exact path="/MyRequests" component={SubNav} />
          <Switch>
            <Route
              exact
              path="/MyRequests/Pending"
              component={PendingRequestsWithData}
            />
            <Route
              exact
              path="/MyRequests/History"
              component={RequestHistoryWithData}
            />
            <Redirect exact from="/MyRequests" to="/MyRequests/Pending" />
            <Redirect from="/" to="/ManageRequests" />
          </Switch>
        </SubLayout>
      </Switch>
    </Layout>
  );
};

export const renderSupport = (role, userAzureObjectId, name, userNt) => {
  return (
    <Layout name={name} userRole="Support" ntId={userNt}>
      <Switch>
        <Route exact path="/ManageRequests" component={ManageRequests} />
        <Route
          path="/MyDelegate"
          render={(props) => (
            <MyDelegateWithData
              {...props}
              userAzureObjectId={userAzureObjectId}
            />
          )}
        />
        <Route
          exact
          path="/Help"
          render={(props) => <Help {...props} role={role} />}
        />
        <Route exact path="/AllRequests" component={AllRequestsWithData} />
        <Route exact path="/Timeline/:id" component={TimelineModal} />
        <Route
          path="/MyRequests/Pending/:id"
          component={RequestTimelineWithData}
        />
        <SubLayout>
          <Route exact path="/MyRequests" component={SubNav} />
          <Switch>
            <Route
              exact
              path="/MyRequests/Pending"
              component={PendingRequestsWithData}
            />
            <Route
              exact
              path="/MyRequests/History"
              component={RequestHistoryWithData}
            />
            <Redirect from="/" to="/MyRequests/Pending" />
          </Switch>
        </SubLayout>
      </Switch>
    </Layout>
  );
};

export const renderUser = (role, x, name, userNt) => {
  return (
    <Layout name={name} userRole="User" ntId={userNt}>
      <Switch>
        <Route
          exact
          path="/Help"
          render={(props) => <Help {...props} role={role} />}
        />
        <Route
          path="/MyRequests/Pending/:id"
          component={RequestTimelineWithData}
        />
        <SubLayout>
          <Route exact path="/MyRequests" component={SubNav} />
          <Switch>
            <Route
              exact
              path="/MyRequests/Pending"
              component={PendingRequestsWithData}
            />
            <Route
              exact
              path="/MyRequests/History"
              component={RequestHistoryWithData}
            />
            <Redirect from="/" to="/MyRequests/Pending" />
          </Switch>
        </SubLayout>
      </Switch>
    </Layout>
  );
};
