import React from "react";
import RingLoader from "react-spinners/PulseLoader";

export default class Ringloader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  render() {
    return (
      <div className="container-fluid text-center" style={{ marginTop: 240 }}>
        <RingLoader
          // style={{
          //   display: "block",
          //   margin: 0,
          //   borderColor: "red",
          // }}
          sizeUnit={"px"}
          size={25}
          color={"#4A90E2"}
          loading={this.state.loading}
        />
      </div>
    );
  }
}
