import "babel-polyfill";
import "react-app-polyfill/ie11";
import "./Themes/Slate.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import SSO from "./SSO";
import plausibleAnalytics from "./plausibleAnalytics";

const WithAuth = () => {
  plausibleAnalytics();
  return (
    <SSO>
      <App />
    </SSO>
  );
};

ReactDOM.render(<WithAuth />, document.getElementById("root"));
unregister();
