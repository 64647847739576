import React from "react";
import avatar from "../../Shared/avatar.png";
import MediaQuery from "react-responsive";
import { authProvider } from "../../Auth/authProvider";

export default class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: avatar,
    };
  }

  componentDidMount = async () => {
    const graphEndpoint =
      "https://graph.microsoft.com/v1.0/me/photos/48x48/$value";
    var token = await authProvider.getAccessToken();
    fetch(graphEndpoint, {
      method: "GET",
      headers: { Authorization: token ? `Bearer ${token.accessToken}` : "" },
    })
      .then((response) => {
        if (response != null && response.ok) {
          response.blob().then((data) => {
            if (data !== null) {
              var img = window.URL.createObjectURL(data);
              this.setState({
                image: img,
              });
            }
          });
        } else {
          this.setState({ image: avatar });
        }
      })
      .catch((response) => {
        this.setState({ image: avatar });
      });
  };

  render() {
    if (this.props.name) {
      var name = "Hello, " + this.props.name;
    }
    let hidden = this.props.hidden;
    return (
      <div className="form-inline">
        <MediaQuery query="(min-width: 640px)">
          <p
            style={{
              marginRight: 8,
              marginTop: 12,
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            {name}{" "}
          </p>
        </MediaQuery>
        <div>
          <img
            alt=""
            style={{
              verticalAlign: "middle",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              visibility: hidden,
              marginRight: 6,
              borderStyle: "solid",
              borderColor: "#696969",
            }}
            src={this.state.image}
          />
        </div>
      </div>
    );
  }
}
