import React, { Component } from 'react';
import SubNav from './SubNav';

export class SubLayout extends Component {
    displayName = SubLayout.name



    render() {
        return (
            <div>
                <SubNav/>
                {this.props.children}
            </div>
        );
    }
}