import React from "react";
import ComcastLogo from "../FullLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FadeLoader from "react-spinners/PulseLoader";

const Loading = (props) => {
  const { error } = props;
  return (
    <div
      style={{
        backgroundColor: "#272B30",
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        top: 0,
      }}
    >
      <div
        style={{
          style: "flex",
          margin: "0px auto",
          textAlign: "center",
          fontSize: 20,
          color: "#DCE0DF",
        }}
      >
        <img src={ComcastLogo} alt="Comcast logo" width="350" />
        <br />
        App Approval
        <br />
        <br />
        {error ? (
          <FontAwesomeIcon
            style={{ marginBottom: 10, color: "red" }}
            icon="times-circle"
          />
        ) : (
          <FadeLoader color="#0275d8" />
        )}
        <br />
        {error
          ? "An error occured loading the site"
          : "Loading, please wait..."}
      </div>
    </div>
  );
};

export default Loading;
