import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Notification from "../components/Notifications";
import favicon from "../../src/favicon.png";
import MediaQuery from "react-responsive";

class Navmenu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  renderAdmin() {
    return (
      <Nav className="mr-auto" navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Requests
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag={Link} to={"/AllRequests"}>
              All Requests
            </DropdownItem>
            <DropdownItem tag={Link} to={"/ManageRequests"}>
              Manage Requests
            </DropdownItem>
            <DropdownItem tag={Link} to={"/MyRequests"}>
              My Requests
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Administration
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag={Link} to={"/ApprovalChains"}>
              Approval Chains
            </DropdownItem>
            <DropdownItem tag={Link} to={"/ApprovalDelegates"}>
              Approval Delegates
            </DropdownItem>
            <DropdownItem tag={Link} to={"/LocalITApprovers"}>
              Local IT Approvers
            </DropdownItem>
            <DropdownItem tag={Link} to={"/Permissions"}>
              Permissions
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <NavItem>
          <NavLink tag={Link} to={"/Help"}>
            Help
          </NavLink>
        </NavItem>
        <NavLink tag={Link} to={"/MyDelegate"}>
          My Delegate
        </NavLink>
      </Nav>
    );
  }

  renderPowerUser() {
    return (
      <Nav className="mr-auto" navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Requests
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag={Link} to={"/ManageRequests"}>
              Manage Requests
            </DropdownItem>
            <DropdownItem tag={Link} to={"/MyRequests"}>
              My Requests
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <NavItem>
          <NavLink tag={Link} to={"/MyDelegate"}>
            My Delegate
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={"/Help"}>
            Help
          </NavLink>
        </NavItem>
      </Nav>
    );
  }
  renderSupport() {
    return (
      <Nav className="mr-auto" navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Requests
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag={Link} to={"/AllRequests"}>
              All Requests
            </DropdownItem>
            <DropdownItem tag={Link} to={"/ManageRequests"}>
              Manage Requests
            </DropdownItem>
            <DropdownItem tag={Link} to={"/MyRequests"}>
              My Requests
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <NavItem>
          <NavLink tag={Link} to={"/MyDelegate"}>
            My Delegate
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={"/Help"}>
            Help
          </NavLink>
        </NavItem>
      </Nav>
    );
  }

  renderUser() {
    return (
      <Nav className="mr-auto" navbar>
        <NavItem>
          <NavLink tag={Link} to={"/Home"}>
            My Requests
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={"/Help"}>
            Help
          </NavLink>
        </NavItem>
      </Nav>
    );
  }

  renderHelper() {
    const { Role } = this.props;
    if (Role === "Admin" || Role === "Developer") {
      return this.renderAdmin();
    }
    if (Role === "Power User") {
      return this.renderPowerUser();
    }
    if (Role === "Support") {
      return this.renderSupport();
    }
    if (Role === "User") {
      return this.renderUser();
    }
  }

  render() {
    let breakpoint = "md";
    return (
      <div>
        <Navbar
          className={`navbar navbar-expand-${breakpoint} navbar-dark bg-primary`}
          light
          expand={breakpoint}
        >
          <img alt="" style={{ height: "40px", width: "40px" }} src={favicon} />
          <NavbarBrand style={{ color: "white" }}> App Approval </NavbarBrand>
          <MediaQuery query="(max-width: 767px)">
            <Nav style={{ marginRight: 5 }} className="d-flex ml-auto">
              <NavItem>
                <Notification name={this.props.name} ntId={this.props.ntId} />
              </NavItem>
            </Nav>
          </MediaQuery>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {this.renderHelper()}
            <MediaQuery query="(min-width: 768px)">
              <Nav className="d-flex ml-auto">
                <NavItem>
                  <Notification name={this.props.name} ntId={this.props.ntId} />
                </NavItem>
              </Nav>
            </MediaQuery>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Navmenu;
