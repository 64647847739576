import { MsalAuthProvider, LoginType } from "react-aad-msal";

const config = {
  auth: {
    authority:
      "https://login.microsoftonline.com/906aefe9-76a7-4f65-b82d-5ec20775d5aa",
    clientId: "31eb0086-44ba-48d9-a312-707e37396b60",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const authenticationParameters = {
  scopes: ["user.read.all", "group.read.all"],
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  LoginType.Redirect
);
