import React, { Component } from "react";
import Navmenu from "./Navbar";

export class Layout extends Component {
  displayName = Layout.name;

  render() {
    return (
      <div>
        <Navmenu
          Role={this.props.userRole}
          name={this.props.name}
          ntId={this.props.ntId}
        />
        {this.props.children}
      </div>
    );
  }
}
