import { approvalChainState } from "../globalState";

export const selectApp = (store, payload) => {
  store.setState({
    approvalChainState: {
      ...store.state.approvalChainState,
      appId: payload,
    },
  });
      //DEBIG
      console.log("ApprovalChain.js selectApp appId:" + payload);
};

export const selectOps = (store, payload) => {
  store.setState({
    approvalChainState: {
      ...store.state.approvalChainState,
      opsId: payload,
    },
  });
};

export const clearApprovalChain = (store, payload) => {
  store.setState({
    approvalChainState,
  });
};

//TODO: Azure Migiration IN PROGRESS - Add applicationName function
export const selectApplicationName = (store, payload) => {
  store.setState({
    approvalChainState: {
      ...store.state.approvalChainState,
      applicationName: payload,
    },
  });
      //DEBIG
      console.log("ApprovalChain.js selectApplicationName applicationName:" + payload);
};
