import gql from "graphql-tag";

//TODO Azure Migration DONE - add userAzureObjectId
export const LOCALITAPPROVERS_QUERY = gql`
  query {
    vRegionalITLeaders {
      id
      name
      title
      region
      regionId
      userId
      statusDescription
      opsId
      subRegion
      userAzureObjectId
    }
  }
`;

export const WESTDIVREGIONS_QUERY = gql`
  query {
    westDivisionRegions {
      id
      region
      opsId
    }
  }
`;

export const HISTORICALRECORD_QUERY = gql`
  query {
    vAppApprovalRecordHistoricalListByUser {
      iD
      applicationName
      status
      endDate
    }
  }
`;

export const APPROVERTIMELINE_QUERY = gql`
  query ($requestId: ID!) {
    vApprovalRecordListByRequestId(requestId: $requestId) {
      id
      approverName
      approverTitle
      currentState
      approvalOrder
      nTLogon
    }
  }
`;

export const APPROVERTIMELINEALL_QUERY = gql`
  query ($requestId: ID!) {
    vApprovalRecordListByRequestIdAll(requestId: $requestId) {
      id
      approverName
      approverTitle
      currentState
      approvalOrder
      nTLogon
    }
  }
`;

export const APPREQUESTS_QUERY = gql`
  query {
    appRequestList {
      id
      applicationName
      requestGuid
      currentState
    }
  }
`;
//TODO: Azure Migration DONE - Add userAzureObjectId
export const ALLUSERS_QUERY = gql`
  query {
    allUsers {
      name
      ntlogon
      pidKey
      jobDescr
      userAzureObjectId
      opsPermissions {
        masterApprover
        ops {
          id
          subRegion
        }
        role {
          roleId
          roleName
          isActive
        }
      }
    }
  }
`;
//TODO: Azure Migration DONE - Add userAzureObjectId to ALLGROUPS_QUERY
export const ALLGROUPS_QUERY = gql`
  query {
    allGroups {
      sid
      name
      description
      opsPermissions {
        masterApprover
        ops {
          id
          subRegion
        }
        role {
          roleId
          roleName
          isActive
        }
      }
    }
  }
`;

export const GETROLES_QUERY = gql`
  query {
    roles {
      roleName
      roleId
      isActive
    }
  }
`;
//TODO: Azure Migration DONE - Add userAzureObjectId to CURRENTUSER_QUERY
export const CURRENTUSER_QUERY = gql`
  query {
    currentUser {
      name
      ntlogon
      pidKey
      userAzureObjectId
      role {
        roleId
        roleName
        isActive
      }
      opsPermissions {
        masterApprover
        role {
          roleId
          roleName
          isActive
        }
        ops {
          id
          subRegion
        }
      }
    }
  }
`;
//TODO Azure Migration DONE - add approverUserAzureObjectId
export const APPROVALRECORDBYID_QUERY = gql`
  query ($id: ID!) {
    vAppApprovalRecordFullListById(id: $id) {
      applicationName
      approverName
      requestorName
      approverId
      requestorTitle
      approverUserAzureObjectId
    }
  }
`;

export const APPROVALCHAIN_QUERY = gql`
  query {
    vApprovalChainApproverCountList {
      applicationId
      applicationName
      licenseType
      cost
    }
  }
`;

//TO
export const APPLICATIONINFO_QUERY = gql`
  query {
    vApprovalChainApproverCountList {
      id
      applicationId
      applicationName
      approvers
      opsId
      subRegion
    }
  }
`;

export const ApproveDeny_query = gql`
  {
    vAppApprovalRecordFullList {
      id
      applicationName
      cost
      licenseType
      requestorName
      requestorTitle
      requestorComments
      requestDate
      currentState
    }
  }
`;

export const REFRESHSCCM_QUERY = gql`
  query {
    refreshSCCM
  }
`;

//TODO: Azure Migration DONE - approverId is PidKey, add approverUserAzureObjectId
export const APPROVERSLIST_QUERY = gql`
  query ($applicationId: Int!, $ops: Int) {
    vApplicationApprovalChainList(applicationId: $applicationId, ops: $ops) {
      id
      approvalOrder
      approverId
      name
      applicationId
      emplStatusDescr
      title
      opsId
      approverUserAzureObjectId
    }
  }
`;
//TODO: Azure Migration DONE - add userAzureObjectId to SEARCH_Query
export const SearchUser_Query = gql`
  query ($ntLogon: String!) {
    vWestDivEmployees(ntLogon: $ntLogon) {
      pidKey
      name
      title
      ntLogon
      userAzureObjectId
    }
  }
`;

export const OPSCENTERS_QUERY = gql`
  query {
    opsCenters {
      id
      opsCenter
      subRegion
      notes
    }
  }
`;
export const APPROVALRECORDALL_QUERY = gql`
  query {
    vAppApprovalRecordFullAll {
      id
      applicationName
      requestorName
      requestDate
      comments
      cost
      license
      currentState
      requestGuid
      applicationId
      opsId
      subRegion
    }
  }
`;

//TODO: Azure Migration DONE - approverId is PidKey, add approverUserAzureObjectId
export const MANAGEREQUESTS_QUERY = gql`
  query {
    vAppApprovalRecordFullList {
      id
      applicationName
      requestorName
      requestorTitle
      requestDate
      requestorComments
      cost
      licenseType
      currentState
      attemptCount
      status
      requestorNt
      approverId
      opsId
      subRegion
      approverUserAzureObjectId
    }
  }
`;

export const AUTOSUGGESTGROUP_QUERY = gql`
  query ($name: String) {
    autoSuggestByGroup(name: $name) {
      sid
      name
      description
    }
  }
`;

//TODO Azure Migration DONE - add userAzureObjectId, add delegateUserAzureObjectId
export const ALLDELEGATES_QUERY = gql`
  query {
    vAllDelegates {
      id
      userPid
      userNt
      userName
      userTitle
      delegatePid
      delegateNt
      delegateName
      delegateTitle
      startDate
      endDate
      userAzureObjectId
      delegateUserAzureObjectId
    }
  }
`;
//TODO Azure Migration DONE - new change parameter to from pidKey to userAzureObjectId, add userAzureObject, add delegateUserAzureObjectId
export const DELEGATEBYUSERAZUREOBJECTID_QUERY = gql`
  query ($userAzureObjectId: Guid!) {
    delegateByUserAzureObjectId(userAzureObjectId: $userAzureObjectId) {
      id
      userPid
      userNt
      userName
      userTitle
      delegatePid
      delegateNt
      delegateName
      delegateTitle
      startDate
      endDate
      userAzureObjectId
      delegateUserAzureObjectId
    }
  }
`;
//TODO: Azure Migration IN PROGRESS -  Original remove this after testing the substituted query DELEGATEBYUSERAZUREOBJECTID_QUERY
export const DELEGATEBYPID_QUERY = gql`
  query ($pidKey: Int!) {
    delegateByPidKey(pidKey: $pidKey) {
      id
      userPid
      userNt
      userName
      userTitle
      delegatePid
      delegateNt
      delegateName
      delegateTitle
      startDate
      endDate
    }
  }
`;

export const SCCMLOOKUP_QUERY = gql`
  query ($name: String) {
    lookupSccmApps(name: $name) {
      id
      appModelId
      applicationName
      collectionId
      collectionName
    }
  }
`;
