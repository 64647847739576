import React from "react";

import { ApolloProvider } from "react-apollo";
import { client } from "./Apollo/Apollo";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "./Auth/authProvider";

const SSO = (props) => {
  return (
    <AzureAD provider={authProvider} forceLogin>
      <ApolloProvider client={client}>{props.children}</ApolloProvider>
    </AzureAD>
  );
};
export default SSO;
