import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  faCheck,
  faStop,
  faStopCircle,
  faBan,
  faEdit,
  faTrashAlt,
  faPlus,
  faEye,
  faInfoCircle,
  faCheckCircle,
  faTimesCircle,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./Loading.css";
import {
  renderAdmin,
  renderPowerUser,
  renderSupport,
  renderUser,
} from "./Routes";
import { graphql } from "react-apollo";
import { CURRENTUSER_QUERY } from "./Apollo/Queries";
import { BrowserRouter as Router } from "react-router-dom";
import useGlobal from "./store/globalState";
import Loading from "./Shared/Loading/Loading";

library.add(
  faCheck,
  faStop,
  faStopCircle,
  faBan,
  faEdit,
  faTrashAlt,
  faPlus,
  faEye,
  faInfoCircle,
  faCheckCircle,
  faTimesCircle,
  faMinus
);

const currentUserHelper = (currentUser) => {
  console.log("CurrentUser from App.js" + JSON.stringify(currentUser));
  const role = currentUser.role.roleName;
  console.log("Current User Role from App.js: " + role);

  const userAzureObjectId = currentUser.userAzureObjectId;
  const name = currentUser.name;
  const ntLogon = currentUser.ntlogon;

  if (currentUser) {
    if (role === "Admin" || role === "Developer") {
      return renderAdmin(role, userAzureObjectId, name, ntLogon);
    } else if (role === "Power User") {
      return renderPowerUser(role, userAzureObjectId, name, ntLogon);
    } else if (role === "Support") {
      return renderSupport(role, userAzureObjectId, name, ntLogon);
    } else if (role === "User") {
      return renderUser(role, userAzureObjectId, name, ntLogon);
    } else {
      return (
        <p style={{ marginTop: 50 }} className="text-center">
          Not Authorized
        </p>
      );
    }
  } else {
    return (
      <p style={{ marginTop: 50 }} className="text-center">
        An Error Occured
      </p>
    );
  }
};

const Root = (props) => {
  const { data } = props;
  const { currentUser } = data;
  const [_, globalActions] = useGlobal();
  const { updateCurrUser } = globalActions;

  useEffect(() => {
    updateCurrUser(currentUser);
  }, [currentUser]);

  if (data.loading) return <Loading error={false} />;
  if (data.error) return <Loading error={true} />;
  return <Router>{currentUserHelper(currentUser)}</Router>;
};
const App = graphql(CURRENTUSER_QUERY)(Root);
export default App;
