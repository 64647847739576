import { permissionsState } from "../globalState";

export const updateMasterAmdmin = (store, payload) => {
  store.setState({
    permissionsState: {
      ...store.state.permissionsState,
      permissionList: payload,
      orgList: [],
    },
  });
};

export const updateOrgList = (store, payload) => {
  const isAdmin = payload.list.find((i) => i.orgId === 11);
  store.setState({
    permissionsState: {
      ...store.state.permissionsState,
      permissionList: payload.list,
      orgList: payload.org,
      isMasterAdmin: isAdmin ? true : false,
    },
  });
};
export const updateRole = (store, payload) => {
  let newState = [...store.state.permissionsState.permissionList];
  let rec = newState.find((i) => i.orgId === payload.orgId);
  rec.roleId = payload.roleId;
  rec.roleName = payload.roleName;
  store.setState({
    permissionsState: {
      ...store.state.permissionsState,
      permissionList: newState,
    },
  });
};

export const updateUser = (store, payload) => {
  store.setState({
    permissionsState: {
      ...store.state.permissionsState,
      //TODO: Azure Migration DONE - replace userId with userAzureObjectId for store state
      //userId: payload,
      userAzureObjectId: payload
    },
  });
};
export const clearPermissionState = (store, payload) => {
  store.setState({
    permissionsState: permissionsState,
  });
};

export const updateIsAdmin = (store, payload) => {
  store.setState({
    permissionsState: {
      ...store.state.permissionsState,
      isMasterAdmin: payload,
    },
  });
};

export const updateGroup = (store, payload) => {
  store.setState({
    permissionsState: {
      ...store.state.permissionsState,
      group: {
        ...payload,
      },
    },
  });
};

export const updateTab = (store, payload) => {
  store.setState({
    permissionsTab: {
      activeTab: payload,
    },
  });
};
