import globalHook from "use-global-hook";

import * as actions from "../actions";

export const approvalChainState = {
  appId: null,
  opsId: null,
  //TODO: Azure Migration IN PROGRESS - Add applicationName
  applicationName: null
};

export const permissionsState = {
  //TODO: Azure Migration DONE - Add userAzureObjectId
  userAzureObjectId: null,
  userId: null,
  group: {
    sid: null,
    description: null,
    name: null,
  },
  permissionList: [],
  orgList: [],
  isMasterAdmin: false,
};
export const permissionsTab = {
  activeTab: "1",
};
export const currentUserState = {
  currentUser: null,
};

const initialState = {
  currentUserState,
  approvalChainState,
  permissionsState,
  permissionsTab,
};

const useGlobal = globalHook(initialState, actions);

export default useGlobal;
