import React from 'react';
import { NavLink } from 'react-router-dom';


class SubNav extends React.Component {
    displayName = SubNav.name
    render() {
        return (
            <div>
                <div style={{ marginTop: 20, width: "600px", maxWidth: "100%" }} className="container-fluid">
                    <p>My Requests</p>
                    <nav className="nav nav-pills nav-fill">
                        <NavLink exact to="/MyRequests/Pending" className="nav-item nav-link"> Pending </NavLink>
                        <NavLink to="/MyRequests/History" className="nav-item nav-link"> Historical </NavLink>
                    </nav>

                </div>
                <hr />
            </div>

            );
    }
}

export default SubNav;
